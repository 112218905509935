console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);


$('.fancybox').fancybox({
  toolbar  : false,
  smallBtn : true,
  iframe : {
    preload : false
  }
})




$('body').materialScrollTop();


$('#js-clickEvent--c-menu__btn-mobile').click(function(){


  $('#js-clickEvent--c-menu__btn-mobile').toggleClass('is-active');
  $('#body').toggleClass('is-active-menu-mobile');
});


window.addEventListener('scroll', function(){
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let element = document.getElementById('js-apper');
  let elementHeader = document.getElementById('header');
  let js_apper = document.getElementById("js-apper");
  let offset = js_apper.getBoundingClientRect().top;
  if (offset - elementHeader.clientHeight  < elementHeader.clientHeight )
    document.getElementById('body').classList.add('is-active-change-color');
  else
    document.getElementById('body').classList.remove('is-active-change-color');

});




// anchor link
 // Add class menu reference archos url load page
 if (window.location.hash!=""){
  var menu__top = document.getElementById("menu-top");
  menu__top.className = "";
  var archos = window.location.hash.substr(1);
  menu__top.classList.add(archos);
}

// Add smooth scrolling to all links
$("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {


      document.getElementById('body').classList.remove('is-active-menu-mobile');
      document.getElementById('js-clickEvent--c-menu__btn-mobile').classList.remove('is-active');

      $('.header__menu').removeClass('is-active');

      
      // Prevent default anchor click behavior
      // event.preventDefault();

      // Store hash
      var hash = this.hash;

      var menu__top = document.getElementById("menu-top");
      menu__top.className = "";

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

      var headerHeight = document.getElementsByTagName("header");
      if (headerHeight[0].classList != "fixed"){

        var topPage = $(hash).offset().top;
      } else {
        var topPage = $(hash).offset().top;
      //console.log(topPage);
    }

    var offsetHeight = document.getElementById('header').offsetHeight;

        // var position = target.offset().top - offsetHeight;

        $('html, body').animate({
          scrollTop: topPage - offsetHeight
        }, 800, function(){

        //console.log($(hash).offset().top);
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;

        var archos = window.location.hash.substr(1);
        menu__top.classList.add(archos);

        var headerHeight = document.getElementsByTagName("header");



      });
    } // End if


  });

// Resize window for web
window.addEventListener("scroll", resizeFunction);
window.addEventListener("resize", resizeFunction);
window.addEventListener("load", resizeFunction);


function resizeFunction() {
  var bodyHeight = document.documentElement.clientHeight;
  //console.log(window.pageYOffset);
}


window.addEventListener("scroll",recorrido);
function recorrido(){
      // sectors = ["empresa","distribucio","elaboracio-propia","hosteleria","contacte"];
      sectors = ["0","1","2","3","4","5"];

      pixelTop = [];for (var i = 0; i < sectors.length; i++) {

        d = document.getElementById(sectors[i]);
        
        pixelTop[i] = d.offsetTop - window.pageYOffset-document.getElementById("header").offsetHeight;

        if (pixelTop[i]<= document.getElementById("header").offsetHeight) {


          // console.log(d.id + ' : ' + d.offsetTop +  ' : ' + pixelTop[i]);


          var menu__top = document.getElementById("menu-top");



          menu__top.className = "";
          menu__top.classList.add('item-'+sectors[i]);

          if ( d.id == "4" && pixelTop[i]<-200){
            menu__top.className = "";
            menu__top.classList.add('item-5');
          }
        }
      }
    }


// anchor link
// Select all links with hashes


/*

$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('.js-no-event-scroll')
  .click(function(event) {
    // On-page links
    $('.c-menu a').removeClass('is-active');
    this.classList = 'is-active';

    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
        ) {
      // Figure out element to scroll to
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();


        var offsetHeight = document.getElementById('header').offsetHeight;

        var position = target.offset().top - offsetHeight;


        $('html, body').animate({
          scrollTop: position
        }, 700, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        });
      }
    }
  });

  */




